import styled from 'styled-components';

export const BtnWrapper = styled.div`
  button {
    background: ${(props) => props.theme.palette.background.bg1};
    box-shadow: ${(props) => props.theme.shadows.s4};
  }
`;

export const DrawerContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const DrawerTitle = styled.div`
  font: 1.25rem/1.5rem Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const DrawerOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DrawerOption = styled.div`
  font: 1rem/1.25rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const DrawerLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.palette.divider.d1};
`;
