import { TitleContainerStatic, TitleContentStatic } from './PageTitleStyle';

interface Props {
  title: string;
}

const StaticPageTitle = (props: Props) => {
  return (
    <TitleContainerStatic>
      <TitleContentStatic>{props.title}</TitleContentStatic>
    </TitleContainerStatic>
  );
};

export default StaticPageTitle;
