import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import {
  StaticBodyRightContainer,
  StaticBodyWrapper,
  StaticLeftContainer,
  StyledLink,
} from '../components/StaticPages/StaticDrawerStyle';
import { StaticPageDrawer } from '../components/StaticPages/StaticPageDrawer';
import { WIDTH } from '../constants/screenResolution';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../utils/getNumberFromResolutionConstant';

interface Props {
  children: ReactNode;
  pageList: PageListProps[];
}

export interface PageListProps {
  title: string;
  slug: string;
}

const StaticPageLayout = ({ children, pageList, ...props }: Props) => {
  const router = useRouter();
  const { width } = useWindowDimensions();
  //   const [pagesArray, setPagesArray] = useState<StaticData[]>([
  //     {
  //         title: 'Terms and Conditions',
  //         slug: '/terms-and-conditions',
  //     },
  //     {
  //         title: 'Privacy Policy',
  //         slug: '/privacy-policy',
  //     },
  //     {
  //         title: 'Refund Policy',
  //         slug: '/refund-policy',
  //     },
  //   ]);

  return (
    <>
      <StaticBodyWrapper>
        <StaticLeftContainer>
          {width && width < getNumFromResolutionConstant(WIDTH.tabletMax) ? (
            <StaticPageDrawer pageList={pageList} />
          ) : (
            pageList?.map((temp: any) => {
              return (
                temp.title !== 'Frequently Asked Questions (FAQs)' &&
                temp.title !== 'faq' &&
                temp.title !== 'Frequently Asked Questions' &&
                temp.title !== 'FAQ' && (
                  <StyledLink
                    selected={
                      temp.slug ===
                      '/' +
                        router.pathname.split('/')[
                          router.pathname.split('/').length - 1
                        ]
                    }
                  >
                    <Link href={temp.slug} passHref key={temp.slug}>
                      <a>{temp.title}</a>
                    </Link>
                  </StyledLink>
                )
              );
            })
          )}
        </StaticLeftContainer>
        <StaticBodyRightContainer>{children}</StaticBodyRightContainer>
      </StaticBodyWrapper>
    </>
  );
};

export default StaticPageLayout;
