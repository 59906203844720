import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const StaticBodyWrapper = styled.div`
  background: ${(props) => props.theme.palette.background.bg2};
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  position: relative;
  gap: 8px;
  justify-content: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    flex-direction: column;
  }
`;

export const StaticBodyRightContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg1};
  margin: 32px 12px 0px 12px;
  border: 1px solid ${(props) => props.theme.palette.divider.d1};
  padding-right: 20px;
  padding-left: 16px;
  border-radius: 8px;
  @media (max-width: ${WIDTH.tabletMax}) {
    max-width: 100%;
    padding-left: 4px;
    padding-right: 4px;
    margin: 0px 16px 16px 16px;
  }
`;

export const StaticLeftContainer: any = styled.div`
  padding: 24px;
  left: 16px;
  top: 115px;
  position: sticky;
  display: flex;
  gap: 12px;
  margin-top: 32px;
  min-width: 282px;
  max-width: 300px;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background.bg1};
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  height: fit-content;
  border-radius: 8px;
  @media (max-width: ${WIDTH.tabletMax}) {
    min-width: 90%;
    max-width: 100%;
    top: 60px;
    gap: 0px;
    margin-bottom: -12px;
    padding: 16px;
    background: ${(props) => props.theme.palette.background.bg2};
    margin-top: 0px;
  }
`;

export const DrawerOptionStaticDrawerStyle = styled.div<{ selected?: boolean }>`
  font: 1rem/1.25rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
  a {
    color: ${(props) =>
      props.selected
        ? props.theme.palette.primary.main
        : props.theme.palette.text.t2};
  }
`;

export const StyledLink = styled.p<{ selected: boolean }>`
  color: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.text.t2};
`;
