import { Drawer, IconButton } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { PageListProps } from '../../Layout/StaticPageLayout';
import {
  DrawerContainer,
  DrawerHeader,
  DrawerLine,
  DrawerOptions,
  DrawerTitle,
} from '../CurrentlySellingProductTypeMobile/CurrentlySellingProductTypeMobileStyle';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import { DrawerOptionStaticDrawerStyle } from './StaticDrawerStyle';

interface Props {
  pageList: PageListProps[];
}

export const StaticPageDrawer = ({ pageList }: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const [drawer, setDrawer] = useState<boolean>(false);

  const handleDrawerState = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  return (
    <>
      {/* <BtnWrapper style={{ minWidth: '100%' }}> */}
      <Button
        buttonTheme="grey"
        style={{
          background: theme.palette.background.bg1,
          border: `1px solid ${theme.palette.divider.d1}`,
          minWidth: '100%',
          justifyContent: 'space-between',
          font: '16px/20px Onest-Medium',
        }}
        onClick={handleDrawerState}
      >
        {pageList?.map((temp: any) => {
          return (
            <>
              {temp.slug ===
              '/' +
                router.pathname.split('/')[
                  router.pathname.split('/').length - 1
                ]
                ? temp.title
                : null}
            </>
          );
        })}
        <MaskIcon
          url="/icons/arrow-down-chevron-24.svg"
          color={theme.palette.text.t1}
          selected
          margin="0 0"
          height="20px"
          width="20px"
        />
      </Button>
      {/* </BtnWrapper> */}
      {drawer && (
        <Drawer
          anchor="bottom"
          onClose={handleDrawerState}
          open={drawer}
          style={{ zIndex: 60 }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              maxHeight: '85vh',
              padding: '0px',
            },
          }}
          PaperProps={{
            square: false,
            style: {
              padding: '0px',
              borderRadius: '8px 8px 0 0',
              background: theme.palette.background.bg1,
            },
          }}
        >
          <DrawerContainer style={{ padding: '0px', marginBottom: '12px' }}>
            <DrawerHeader style={{ marginTop: '2px', marginBottom: '-12px' }}>
              <DrawerTitle
                style={{
                  font: '20px/27px Onest-Medium',
                  marginLeft: '26px',
                  marginTop: '8px',
                }}
              >
                Select an option
              </DrawerTitle>
              <IconButton
                onClick={handleDrawerState}
                sx={{ m: '0px 12px 0px 0' }}
              >
                <MaskIcon
                  selected
                  url="/icons/close-24.svg"
                  height="20px"
                  width="20px"
                  margin="0 0"
                  color={theme.palette.text.t1}
                />
              </IconButton>
            </DrawerHeader>
            <DrawerOptions style={{ cursor: 'pointer' }}>
              {pageList?.map((temp: any) => {
                return (
                  // <StyledLink selected={temp.slug === '/' + router.query.pages}>
                  //   <Link href={'/info' + temp.slug} passHref key={temp.slug}>
                  //     <a>{temp.title}</a>
                  //   </Link>
                  // </StyledLink>
                  <>
                    {temp.title !== 'Frequently Asked Questions (FAQs)' && (
                      <>
                        <DrawerLine />
                        <DrawerOptionStaticDrawerStyle
                          selected={
                            temp.slug ===
                            '/' +
                              router.pathname.split('/')[
                                router.pathname.split('/').length - 1
                              ]
                          }
                          onClick={() => {
                            handleDrawerState();
                          }}
                        >
                          <Link href={temp.slug} passHref key={temp.slug}>
                            <a
                              style={{
                                margin: '0px 26px 8px 26px',
                              }}
                            >
                              {temp.title}
                            </a>
                          </Link>
                        </DrawerOptionStaticDrawerStyle>
                      </>
                    )}
                  </>
                );
              })}
            </DrawerOptions>
          </DrawerContainer>
        </Drawer>
      )}
    </>
  );
};
